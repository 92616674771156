import "./App.css";
import Navigation from "./Navigation";
import { AuthProvider } from "./Service/AuthService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastProvider } from "./GlobalComponent/ToastMessage/ToastContext";
import { BrowserRouter as Router } from "react-router-dom";
function App() {

  return (
    <>
 <Router>
        <AuthProvider>
          <ToastProvider>
            <Navigation />
            <ToastContainer style={{ zIndex: 10999 }} />
          </ToastProvider>
        </AuthProvider>
      </Router>
    </>
     
    
  );
}

export default App;

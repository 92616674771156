import React, { useEffect,lazy,Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./Service/AuthService";
import Loader from "./GlobalComponent/Loader/Loader";
const SignIn = lazy(() => import("./Component/LoginUser/SignIn"));
const Home = lazy(() => import("./Component/Home/Home"));

function Navigation() {
  const { token } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [token]);

  return (
    <Suspense fallback={<Loader/>}>
    <Routes>
    {token ? (
      <>
        <Route exact path="/" element={<Home />} />
      </>
    ) : (
      <Route exact path="/login" element={<SignIn />} />
    )}
  </Routes>
  </Suspense>
  );
}

export default Navigation;

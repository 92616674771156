import axios from 'axios';
import Cookies from "js-cookie";
const csrfToken = Cookies.get("csrftoken");

const createAbortController = () => new AbortController();

const protocol = window.location.protocol;
// Set the API URL based on the protocol
const API_URL =
  protocol === "http:"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

async function fetchData(url,token,signal){
    try{
        const response = await axios.get(url,{
            headers:{   "X-CSRFToken": csrfToken,'Authorization': `token ${token}`, 'Content-Type': 'application/json' }
            ,signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error.response};
    }
}
async function PostData(url,data,signal){
    try{
        const response = await axios.post(url,data,{signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error?.response?.data};
    }
}
async function PostDatawithToken(url,data,token,signal){
    try{
        const response = await axios.post(url,data,{
            headers:{   "X-CSRFToken": csrfToken,'Authorization': `token ${token}`, 'Content-Type': 'application/json' }
            ,signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error?.response?.data};
    }
}
async function PostwithotDatawithToken(url,token,signal){
    console.log(token)

    try{
        const response = await axios.post(url,{},{
            headers:{   "X-CSRFToken": csrfToken,'Authorization': `token ${token}`, 'Content-Type': 'application/json' }
            ,signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error.response};
    }
}
async function patchData(url,data,token,signal){
    try{
        const response = await axios.patch(url,data,{
            headers:{   "X-CSRFToken": csrfToken,'Authorization': `token ${token}`, 'Content-Type': 'application/json' }
            ,signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error?.response};
    }
}
async function putData(url,data,token,signal){
    try{
        const response = await axios.put(url,data,{
          headers:{   "X-CSRFToken": csrfToken,'Authorization': `token ${token}`, 'Content-Type': 'application/json' }
          ,signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error.response};
    }
}
async function deletData(url,signal){
    try{
        const response = await axios.delete(url,{signal});
        return {status:true, response:response.data};
    }catch(error){
        return {status:false, response:error.response};
    }
}

export function logout_user(token){
    const abortController = createAbortController();
    const apiUrl =`${API_URL}logout/`;
    return PostDatawithToken(apiUrl,{},token, abortController.signal).finally(() =>
        abortController.abort()
      );
}
export function loginuser(data,signal){
    const apiUrl =`${API_URL}login/`;
    return PostData(apiUrl,data,signal);
}
export function ckeck_token_auth(data){
    const apiUrl =`${API_URL}token-valid/`;
    return PostData(apiUrl,data);
}
export function daily_report_update(data,token,signal){
  const apiUrl =`${API_URL}daily-report-update/`;
  return PostDatawithToken(apiUrl,data,token,signal);
}


  export function get_apps(token,signal) {
    const apiUrl = `${API_URL}apps-permissions/`;
    return fetchData(apiUrl,token,signal);
  }
  export function get_apps_by_user(token,signal,user_id) {
    const apiUrl = `${API_URL}apps-permissions/${user_id}/`;
    return fetchData(apiUrl,token,signal);
  }
//   =========================get email list =======================
  export function get_email_list(token,signal) {
    const apiUrl = `${API_URL}emails-list/`;
    return fetchData(apiUrl,token,signal);
  }
  //   =========================get user list =======================
  export function get_assign_user(token,signal) {
    const apiUrl = `${API_URL}users-list/`;
    return fetchData(apiUrl,token,signal);
  }
    //   =========================get user list =======================
    export function get_task_assign_user(task_id,token,signal) {
      const apiUrl = `${API_URL}users-list/task/?task_id=${task_id}`;
      return fetchData(apiUrl,token,signal);
    }
// ==================================create user====================================
export function create_user(data,token,signal,is_superuser,usertype,type) {
    let apiUrl=""
if(is_superuser){
   apiUrl  = `${API_URL}users/`;
}else if(usertype === "admin"){
    apiUrl  = `${API_URL}users/?type=${type}`;
}
    return PostDatawithToken(apiUrl,data,token,signal);
  }
  // ==================================reset user password====================================
export function reset_password(data,token,signal,user_id) {
   const  apiUrl  = `${API_URL}reset-password/${user_id}/`;
    return PostDatawithToken(apiUrl,data,token,signal);
  }
    // ==================================Email send====================================
export function send_email(data,token,signal) {
    const  apiUrl  = `${API_URL}email/`;
     return PostDatawithToken(apiUrl,data,token,signal);
   }
  // ==================================App and appitem permission====================================
  export function update_permission(token,signal,user_id,app_id) {
    const  apiUrl  = `${API_URL}permissions/${user_id}/${app_id}/`;
     return PostwithotDatawithToken(apiUrl,token,signal);
   }
  // ==================================Assign user ====================================
  export function update_module_assign(token,data,signal) {
    const  apiUrl  = `${API_URL}tasks-module-assign/`;
     return PostDatawithToken(apiUrl,data,token,signal);
   }
//-===============================create user=======================================

  export function get_users(page,token,signal) {
    const  apiUrl = `${API_URL}users/?page=${page}`;
    return fetchData(apiUrl,token,signal);
  }
  export function get_search_users(search,token,signal) {
    const  apiUrl = `${API_URL}users/?search=${search}`;
    return fetchData(apiUrl,token,signal);
  }
  export function get_user_list(token,signal) {
    const  apiUrl = `${API_URL}users-list/`;
    return fetchData(apiUrl,token,signal);
  }
//-===============================get total  user count=======================================

export function get_users_count(token,signal,active) {
    let apiUrl=""
    if (active){
      apiUrl = `${API_URL}users/count/?active=${active}`;
    }else{
        apiUrl = `${API_URL}users/count/`;
    }
        // const apiUrl = `${API_URL}users/count/`;
    return fetchData(apiUrl,token,signal);
  }
  //-===============================get total active user count=======================================
// export function get_active_users_count(token,signal) {
//     const apiUrl = `${API_URL}users/count/?active=true`;
// return fetchData(apiUrl,token,signal);
// }
// ===============user update========================
export function user_update(data,token){
    const abortController = createAbortController();
    const apiUrl =`${API_URL}user-update/`;
    return patchData(apiUrl,data,token, abortController.signal).finally(() =>
        abortController.abort()
      );
}
// ============================get user detail using token=====================
export function refresh_user_detail(data) {
    const apiUrl = `${API_URL}user-token/`;
    const abortController = createAbortController();
    return PostData(apiUrl,data, abortController.signal).finally(() =>
      abortController.abort()
    );
  }
  //   =======================================daily report============================
export function create_daily_report(data,token,signal){
  const apiUrl =`${API_URL}reports/`;
  return PostDatawithToken(apiUrl,data,token,signal);
  }
  export function get_daily_report(user_id,date,page,token,signal) {
    const  apiUrl = `${API_URL}reports-list/?user_id=${user_id}&date=${date}&page=${page}`;
    return fetchData(apiUrl,token,signal);
  }
//   =======================================Task============================
export function create_task(data,token,signal){
    const apiUrl =`${API_URL}tasks/`;
    return PostDatawithToken(apiUrl,data,token,signal);
    }
    export function update_task(data,token,task_id,signal){
      const apiUrl =`${API_URL}tasks/${task_id}`;
      return putData(apiUrl,data,token,signal);
      }
   export function get_task(page,token,signal) {
        const  apiUrl = `${API_URL}tasks/?page=${page}`;
       return fetchData(apiUrl,token,signal);
    }
    export function get_task_list(token,signal) {
      const  apiUrl = `${API_URL}tasks/list/`;
     return fetchData(apiUrl,token,signal);
  }
  export function get_module_list(token,task_id,signal) {
    const  apiUrl = `${API_URL}module/list/${task_id}/`;
   return fetchData(apiUrl,token,signal);
}
export function get_Task_progress_list(token,task_id,signal) {
  const  apiUrl = `${API_URL}task-project/${task_id}/`;
 return fetchData(apiUrl,token,signal);
}
// =================dashboard=================================
export function get_dashboard(token,signal) {
  const  apiUrl = `${API_URL}dashboard/`;
 return fetchData(apiUrl,token,signal);
}
export function show_dashboard_data(page,data,token,signal){
  const apiUrl =`${API_URL}dashboard/?page=${page}`;
  return PostDatawithToken(apiUrl,data,token,signal);
  }
//   =================================hr section==================================
export function hr_lead(data,token,signal){
const apiUrl =`${API_URL}/`;
return patchData(apiUrl,data,token,signal);
}

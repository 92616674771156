import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import "./ToastMessage.css"
function ToastMessage({ show, onClose, title, message }) {

  return (
    <Toast show={show} className="position-fixed bottom-0 end-0 m-3 ">
      <Toast.Header closeButton={false} style={{ backgroundColor: "#AD003B" }}>
        <i class="fa-solid fa-circle-info text-primary"></i>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto text-light">{title}</strong>
        {/* <small>{formatTime()} ago</small> */}
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
      <button className='demo-select-option-edit-close-btn-toast  border rounded-circle' onClick={onClose} aria-label="Close" style={{ position: "absolute", top: "5px", right: "5px" }}><i class="fa-solid fa-xmark fa-1x"></i></button>
    </Toast>

  );
}

export default ToastMessage;
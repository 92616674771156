import { createContext, useContext, useEffect, useState } from 'react';
import { ckeck_token_auth, loginuser, logout_user, refresh_user_detail} from './ApiServices';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
const AuthContext = createContext();
// Secret key for encryption (keep it secure)
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
// Function to encrypt data
export const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  return encryptedData;
};
// Function to decrypt data
export const decryptData = (encryptedData) => {
  try {
    if (!encryptedData) {
      return null;
    }
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null; // Return null or handle the error in a way that makes sense for your application
  }
};
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? decryptData(storedUser) : null;
  });
  const [token, settoken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? decryptData(storedToken) : null;
  });



  useEffect(() => {
    // Save user details to local storage whenever user state changes
    localStorage.setItem('user', encryptData(user));
    localStorage.setItem('token', encryptData(token));
  
  }, [user, token]);



  const login = (data) => {
    // const controller = new AbortController();
    return loginuser(data).then(res => {
      if (res.status) {
          settoken(res.response.token);
          setUser(res.response.data);
        return { status: true, response: res.response.data, message: res.response.message };
      } else {
        return { status: false, response: res.error, message: res.response.data.message };
      }
    })
    // .finally(()=>{
    //     controller.abort();
    // });


    

  };

  const logout = () => {
    logout_user(token).then(res => {
      if (res.status) {
        localStorage.setItem('user', encryptData(null));
        localStorage.setItem('token', encryptData(null));
        settoken(null);
        setUser(null);
        toast.info("Logout Successfully!")
      }
    })
  };


  const checkValidToken = (usertoken) => {
    ckeck_token_auth({token:usertoken}).then(res => {
      if (res.status === false) {
          localStorage.setItem('user', encryptData(null));
          localStorage.setItem('token', encryptData(null));
          settoken(null);
          setUser(null);
      }
    })
  }
  const updateuser = () => {
    refresh_user_detail({token:token}).then(res => {
      if (res.status) {
        setUser(res.response.data);
      }
    })
  }
  return (
    <AuthContext.Provider value={{ user, token, login, logout, checkValidToken,updateuser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

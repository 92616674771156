// ToastContext.js
import React, { createContext, useState, useContext } from 'react';
import ToastMessage from './ToastMessage'; // assuming you have a Toast component

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastTitle, setToastTitle] = useState('');

  const showToast = (title,message) => {
    setToastMessage(message);
    setToastTitle(title);
  };

  const closeToast = () => {
    setToastMessage('');
  };

  return (
    <ToastContext.Provider value={{ showToast, closeToast }}>
      {children}
      <ToastMessage show={!!toastMessage} onClose={closeToast} title={toastTitle} message={toastMessage} />
    </ToastContext.Provider>
  );
};
